import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, share, takeWhile } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { selectCurrencySettings } from '../../store/selectors/payment.selectors';
import { GlobalState } from '../../store/store';
import { PayPalEvent } from '../constants/paypal-event.enum';
import { ScriptService, Scripts } from './scripts.service';

declare const braintree: any;
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

@Injectable()
export class BraintreeService {
    private client: any;
    private paypalCheckoutInstance: any;
    private authorizationSubject: Subject<any> = new Subject();
    private deviceDataSubject: ReplaySubject<string> = new ReplaySubject();
    public paypalAuthorization$: Observable<any> = this.authorizationSubject.pipe(share());
    public deviceData$: Observable<any> = this.deviceDataSubject.asObservable();

    constructor(private scriptService: ScriptService, private store: Store<GlobalState>) {}

    public init(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            if (this.client) {
                return resolve();
            }

            await this.scriptService.load(
                Scripts.BRAINTREE,
                Scripts.BRAINTREE_CHECKOUT,
                Scripts.BRAINTREE_DATA_COLLECTOR
            );

            this.store
                .select(selectCurrencySettings)
                .pipe(
                    takeWhile(settings => settings != null),
                    filter(settings => settings != null)
                )
                .subscribe(settings => {
                    return braintree.client
                        .create({
                            authorization: environment.braintreeTokenizationKey
                        })
                        .then(client => {
                            this.client = client;

                            return braintree.paypalCheckout.create({ client });
                        })
                        .then(paypalCheckoutInstance => {
                            this.paypalCheckoutInstance = paypalCheckoutInstance;

                            return paypalCheckoutInstance.loadPayPalSDK({
                                intent: 'tokenize', // Braintree defaults this to 'authorize'
                                currency: settings?.currency.code,
                                commit: true,
                                vault: true
                            });
                        })
                        .then(() => {
                            resolve();
                            return braintree.dataCollector.create({
                                client: this.client,
                                paypal: true
                            });
                        })
                        .then(dataCollectorInstance => {
                            this.deviceDataSubject.next(dataCollectorInstance.deviceData);
                        })
                        .catch(error => {
                            console.error('Error initializing Braintree: ', error);
                        });
                });
        });
    }

    public authorizePayment() {
        return async (data, actions) => {
            const tokenizePayload = await this.paypalCheckoutInstance.tokenizePayment(data);

            this.authorizationSubject.next({
                type: PayPalEvent.AUTHORIZATION,
                data: tokenizePayload
            });

            return tokenizePayload;
        };
    }

    public createPayment() {
        return () => {
            return this.paypalCheckoutInstance.createPayment({
                flow: 'vault',
                billingAgreementDescription: 'GameLeap Service Billing Agreement'
            });
        };
    }

    public cancelPayment() {
        return () => {
            return this.cancelAuthorization();
        };
    }

    public cancelAuthorization() {
        this.authorizationSubject.next({ type: PayPalEvent.CANCELLATION });
    }
}
