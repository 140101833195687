import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WindowRef } from '../../shared/services/window.service';
import { ScriptService, Scripts } from './scripts.service';

declare var Stripe;


@Injectable()
export class StripeService {
    private stripe;
    private elements;

    constructor(private window: WindowRef, private scriptService: ScriptService) {}

    /**
     * Initializes the stripe client.
     * @param {string} publishableKey
     * @returns {any}
     */
    async init(publishableKey: string) {
        await this.scriptService.loadScript(Scripts.STRIPE);
        if (Stripe != null) {
            this.stripe = new Stripe(publishableKey);
            this.elements = this.stripe.elements;
        }
        return this.stripe;
    }

    /**
     * Creates a new token from a credit card's details.
     * @param {object} cardData
     * @param {string} cardData.number
     * @param {string} cardData.cvc
     * @param {string} cardData.exp
     * @returns {Observable<any>}
     */
    public createToken(cardData) {
        return new Observable(observer => {
            this.stripe.createToken(cardData).then(result => {
                if (result.error) {
                    return observer.error(result.error);
                }

                return observer.next(result.token);
            });
        });
    }

    /**
     * Creates a new payment method.
     * @param {object} data
     * @param {object} data.card - Stripe Elements Card object
     * @param {string} data.email
     * @returns {Observable<any>}
     */
    public createPaymentMethod(data) {
        return new Observable(observer => {
            this.stripe
                .createPaymentMethod({
                    type: 'card',
                    card: data.card,
                    billing_details: {
                        email: data.email
                    }
                })
                .then(result => {
                    if (result.error) {
                        return observer.error(result.error);
                    }

                    return observer.next(result.paymentMethod);
                });
        });
    }

    /**
     * Executes SCA 3D Secure flow when required.
     * @param {string} secret - the payment intent secret
     * @param {string} paymentMethodId - the payment method to charge
     * @returns {Observable<any>}
     */
    public confirmCardPayment(secret, paymentMethodId) {
        return new Observable(observer => {
            this.stripe.confirmCardPayment(secret, {
                payment_method: paymentMethodId
            }).then(result => {
                if (result.error) {
                    return observer.error(result.error);
                }

                return observer.next(result);
            });
        });
    }

    /**
     * Creates a Stripe elements object.
     * @returns {any}
     */
    public getElementsConstructor() {
        return this.elements;
    }
}
